import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"

const DemoProducts = () => {
  const breadcrumbs = [{ link: "/", link_text: "Produkte" }]

  const body = [
    {
      component: "product_listing_promo_block",
      kicker: "Ein Akku für Alles",
      text: "Lerne die Vorteile der kabellosen Freiheit auf der Baustelle kennen",
      link: "./",
      image: {
        filename: "https://picsum.photos/1920/1080",
      },
    },
  ]

  return (
    <Layout>
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default DemoProducts
